import { Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsRapUser } from '../../modules/auth/auth.selectors';
import { PaceSetterSituation } from '../../modules/issue/issue.types';
import { TaggingService } from '../../modules/tagging/tagging.service';
import { selectProblemTooltip } from '../../modules/ui/ui.selectors';
import { AAAStore } from '../../store/root-reducer';
import { isMobileApp } from '../utils/app-detect'
import { AppVersion } from '../utils/app-version';
import { PrivacyPolicyService } from '../services/privacy-policy.service';
import { TermsConditionsService } from '../services/terms-conditions.service';
import events from '../../modules/tagging/events';
import { AdobeEventTypes } from '../../modules/tagging/tagging.types'
import { AdobeEventService } from '../../modules/tagging/adobe/event-adobe.service'
import { ConfigService } from '../../modules/config/config.service'

@Component({
  selector: 'app-legal-footer',
  templateUrl: './legal-footer.component.html',
  styleUrls: ['./legal-footer.component.scss']
})

export class LegalFooterComponent {
  appVersion: string
  appEnv: string | boolean = this.configService.getConfig().description
  displayVersion: boolean = this.configService.getConfig().displayVersion
  displayEnv = Boolean(this.configService.getConfig().description)
  isDesktop: boolean

  problemTooltip$: Observable<PaceSetterSituation | null> = this.store$.pipe(
    select(selectProblemTooltip)
  )
  isRapUser$ = this.store$.pipe(select(selectIsRapUser))


  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private appVersionObj: AppVersion,
    private privacyPolicyService: PrivacyPolicyService,
    private termsConditionsService: TermsConditionsService,
    private adobeEventService: AdobeEventService,
    private configService: ConfigService,
  ) {
    this.appVersion = this.appVersionObj.get(this.configService.getConfig().nativeAppView)
  }

  openPrivacyPolicy() {
    this.sendAnalyticsEvent(events.shared.PRIVACY_POLICY_CLICK)
    this.privacyPolicyService.open()
  }

  openTerms($event: Event) {
    $event.preventDefault()
    $event.stopPropagation()

    this.sendAnalyticsEvent(events.shared.TERMS_AND_CONDITIONS_CLICK)

    this.termsConditionsService.open()
  }

  private sendAnalyticsEvent(event: string) {
    this.taggingService.setClickEvent(event, events.location.LOCATION_PAGE_TYPE)
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: event
    })
  }

  isWebApp() {
    return !isMobileApp()
  }
}
