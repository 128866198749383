<ng-container *ngIf="!(activeStep$ | async)">
  <app-split-panel [showSecondaryOnMobile]="false">
    <app-what-happened primary></app-what-happened>
  </app-split-panel>
</ng-container>

<ng-container *ngIf="(activeSituationName$ | async) as activeSituationName">
  <div *ngIf="(activeSituation$ | async) && (activeStep$ | async)">
    <form #f="ngForm" id="select-issue-form" (ngSubmit)="validate(f) && next($event, activeSituationName)" novalidate>
      <app-situation-editor [containingForm]="f"
        [template]="currentTemplate$ | async">
      </app-situation-editor>
    </form>
  </div>
</ng-container>
