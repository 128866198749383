import { Component, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { PaceSetterOptions, PaceSetterSituation } from './../issue.types'
import { selectActiveSituationTemplate, } from './../issue.selectors'
import { Observable } from 'rxjs'
import { setPaceSetterSituation, setPaceSetterStep } from './../issue.actions'
import { TaggingService } from '../../tagging/tagging.service'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import events from '../../tagging/events'
import { selectIsRapUser, selectIsVehicleChangeAllowed, selectModeConfiguration } from '../../auth/auth.selectors'
import { VendorConfigurationMode } from '../../auth/mode-configuration.types'
import { Title } from '@angular/platform-browser';
import { RapService } from '../../rap/rap.service';
import { buildTitle } from '../../../shared/utils/title';
import { EditService } from '../../../shared/services/edit.service'
import { VehicleData } from '../../member/member.types';
import { AbstractComponent } from '../../../shared/abstract.component';
import { selectMemberActiveVehicle } from '../../member/member.selectors';

const TITLE_PAGE = () => $localize`What Happened?`

@Component({
  styleUrls: ['./issue-step.component.scss'],
  templateUrl: './issue-step.component.html',
  selector: 'app-issue-step',
  encapsulation: ViewEncapsulation.None,
})
export class IssueStepComponent extends AbstractComponent {

  currentTemplate$: Observable<PaceSetterOptions> = this.store$.pipe(
    select(selectActiveSituationTemplate)
  )

  modeConfiguration$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )
  rapConfig: VendorConfigurationMode

  isVehicleChangeAllowed$ = this.store$.select(selectIsVehicleChangeAllowed)
  isVehicleChangeAllowed: boolean

  memberActiveVehicle$ = this.store$.select(selectMemberActiveVehicle)
  memberActiveVehicle: VehicleData

  isRapUser$ = this.store$.pipe(select(selectIsRapUser))

  isIssueSelected = false

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private titleService: Title,
    private rapService: RapService,
    private editService: EditService,
  ) {
    super()
    this.titleService.setTitle(buildTitle(TITLE_PAGE(), this.rapService.isRapUser()))
    this.taggingService.setPageEvent(events.issue.ISSUE_PAGE_PROMPT, events.issue.ISSUE_PAGE_TYPE)

    this.subscriptions.push(
      this.modeConfiguration$.subscribe((configData: VendorConfigurationMode) => this.rapConfig = configData),
      this.memberActiveVehicle$.subscribe((vehicle) => this.memberActiveVehicle = vehicle),
      this.isVehicleChangeAllowed$.subscribe((allowed) => this.isVehicleChangeAllowed = allowed),
    )
  }

  problemSelected(problem: PaceSetterSituation) {
    this.store$.dispatch(setPaceSetterSituation({
      payload: {
        ...problem,
        allowChangeVehicle: this.isVehicleChangeAllowed,
        ...(this.memberActiveVehicle?.fuelType ? { vehicleFuelType: this.memberActiveVehicle.fuelType} : {})
      }
    }))
    this.store$.dispatch(addPartialCallRequest())

    this.taggingService.setClickEvent(events.issue.ISSUE_PAGE_NEXT_CLICK, events.issue.ISSUE_PAGE_TYPE)

    this.store$.dispatch(setPaceSetterStep({ index: 1 }))
    this.isIssueSelected = true
  }

  handleCloseSituation() {
    this.isIssueSelected = false
  }

  navigateBack() {
    this.editService.navigateBack();
  }

}
