<ion-card class="ion-no-margin ion-margin-vertical">
  <ion-list>
    <ng-container *ngIf="isHomeAddressVisible">
      <ion-item appCtaClickEvent="Use Home Address" (click)="handleHomeLocationClick()"
                [disabled]="isDisabled" lines="none" button [detail]="!isHomeSpinnerVisible"
                i18n-aria-label aria-label="My Home Address" class="use-home-address">
        <aaa-icon aria-hidden="true" name="home" slot="start" color="tertiary" size="small"></aaa-icon>
        <ion-spinner [hidden]="!isHomeSpinnerVisible" slot="end"></ion-spinner>
        <ion-label>
          <aaa-text size="footnote" color="dark" weight="bold" i18n>My Home Address</aaa-text>
          <aaa-text class="description" size="caption" color="medium" i18n>Choose your home location</aaa-text>
        </ion-label>
      </ion-item>
      <hr>
    </ng-container>

    <ng-container *ngIf="gpsDisabled else gpsDefault">
      <ion-item [disabled]="true" lines="none" button i18n-aria-label aria-label="Current Location Disabled" class="find-my-location">
        <aaa-icon aria-hidden="true" name="current-location-off" slot="start" color="tertiary" size="small"></aaa-icon>
        <ion-label>
          <aaa-text size="footnote" color="dark" weight="bold" i18n>Use Current Location</aaa-text>
          <aaa-text class="description" size="caption" color="medium" i18n>We could not determine your location</aaa-text>
        </ion-label>
      </ion-item>
    </ng-container>

    <ng-template #gpsDefault>
      <ion-item (click)="handleCurrentLocationClick()" [disabled]="isDisabled" lines="none" button class="find-my-location"
                [detail]="!isCurrentLocationSpinnerVisible" i18n-aria-label aria-label="Use Current Location">
        <aaa-icon aria-hidden="true" name="current-location" slot="start" color="tertiary" size="small"></aaa-icon>
        <ion-spinner [hidden]="!isCurrentLocationSpinnerVisible" slot="end"></ion-spinner>
        <ion-label>
          <aaa-text size="footnote" color="dark" weight="bold" i18n>Use Current Location</aaa-text>
          <aaa-text class="description" size="caption" color="medium" i18n>Sharing you location helps us finding you</aaa-text>
        </ion-label>
      </ion-item>
    </ng-template>
  </ion-list>
</ion-card>
