import { BasicContactInfo } from '../../shared/types'
import { AgentSettingsParams } from '../auth/auth.types'
import { PACE_SETTER_SITUATION_CODES } from '../issue/issue.types'
import {
  BreakdownLocation,
  GenericCoordinates,
  TowLocation,
} from '../location/location.types'
import { VehicleData } from '../member/member.types'

export interface CallCancelParams {
  updateToken: string
  callDate: string
  callId: string
  servicingClub: string
}

export interface CallCancelSuccess {
  callId: string
  callDate: string
}

// TODO manage inconsistent gateway types
export interface BreakdownLocationParams {
  latitude: number
  longitude: number
  zip: string
  state: string
  city: string
  street: string
  streetNumber: string
  country?: string
  location?: string
  landmark?: string
  driverDirections?: string
  streetAddress?: string
}

export interface AAACallData {
  club: string
  priorityCode?: string
  cashRequired?: boolean
  callTaker?: string
  agentFirstName?: string
  agentLastName?: string
  comments?: string
  breakdownLocation: BreakdownLocationParams
  contactInfo: ContactInfo
  situationCodes: {
    pacesetterCode: string,
    prioritySituation?: boolean
  }
  towing: TowLocation
  vehicle: VehicleData
  numberOfPassengers?: number
  channel?: string
}

export interface AAATracking {
  supported: boolean
  provider?: string
  url?: string
}

export interface AAACallStatuses {
  callStatuses: AAACallStatus[]
  callHistory: AAACallStatus[]
}

export interface AAACallStatus {
  callId: string
  callDate: string
  callToken: string
  updateToken: string
  trackToken: string
  callStatus: CALL_STATUS_CODES
  pta: string | Date
  servicingClub?: string
  pacesetterCode: PACE_SETTER_SITUATION_CODES
  serviceProvider?: string
  vehicle: VehicleData
  // TODO investigate this curveball on response data.
  breakdownLocation: BreakdownLocation | BreakdownLocationParams
  towDestination?: TowLocation
  driverData: {
    eta: string
    etaUTC?: string | Date,
    code?: string
    gpsAccuracy?: number
    gpsTimeTime?: string
    name: string
    id: string | number
    facilityId: string
  } & GenericCoordinates
  tracking?: AAATracking
  _retries?: number // internal use
}

export interface AAACallCancel {
  updateToken: string
  callDate: string
  callId: string
  servicingClub: string
}

export interface CallResponse {
  callId: string
  callDate: string
  servicingClub: string
  callStatus: string
  callToken: string
  updateToken: string
  trackToken: string
  pta: string
  ptaInLocalTime: string
}

export interface CallSubmission {
  data: AAACallData
  response: CallResponse & {
    callStatus: CALL_STATUS_CODES
  }
}

export interface ClubSupport {
  supported: boolean
}

export interface RequestFailure {
  ignoreFailure: boolean
}

export type ContactInfo = BasicContactInfo & {
  enableEmail: boolean
  enablePushNotifications: boolean
  enableSMS: boolean
  languagePreference: string
}

export enum CALL_STATUS_CODES {
  NEW = 'NEW', // Call submit success. Call status pending.
  RE = 'RE', // Call status response: Received
  IC = 'IC', // Call status response: In Cache (Received by the Gateway but not created yet, to be shown as Received)
  ER = 'ER', // Call status response: En Route
  AS = 'AS', // Call assigned to driver
  OL = 'OL', // Call status response: On Location
  OS = 'OS',
  UT = 'UT',
  TW = 'TW', // Call status response: Towing
  CA = 'CA', // Call status response: Cancelled
  XX = 'XX', // Call status response: Cancelled
  CL = 'CL', // Call status response: Completed
  CP = 'CP', // Call status response: RAP Close Pending same as Completed
  HD = 'HD', // Call status response: RAP HD same as Completed
}

// start ARR:POC - The types for the service delivery data
export interface ServiceDeliveryPhone {
  number: string
  primary: boolean
  sms: boolean
}

export interface ServiceDeliveryCallStatusHistory {
  timestamp: string
  stat: CALL_STATUS_CODES
  updateStat: CALL_STATUS_CODES
  reason: string
  facility?: string
}

export interface ServiceDeliveryCallStatus {
  callDate: string
  callId: string
  pta: string
  phone: ServiceDeliveryPhone[]
  callStatusHist: ServiceDeliveryCallStatusHistory[]
}
// end ARR:POC
export interface AgentSettings extends AgentSettingsParams {
  club: string
}

export enum CALL_STATUS_ITEM_STATE {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING'
}
