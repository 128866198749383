import { ArrivalTimeSummaryComponent } from './arrival-time-summary/arrival-time-summary.component'
import { ProgressMapComponent } from './progress-map/progress-map.component'
import { ServiceSummaryComponent } from './service-summary/service-summary.component'
import { StatusUpdateComponent } from './status-update/status-update.component'
import { BatteryQuotesBannerComponent } from './battery-quotes-banner/battery-quotes-banner.component'
import { ExternalTrackerComponent } from './external-tracker/external-tracker.component'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { InformativeContentComponent } from './informative-content/informative-content.component'
import { CallStatusItemComponent } from './call-status-item/call-status-item.component'
import { EtaLabelComponent } from './eta-label/eta-label.component';
import { FindingServiceProviderComponent } from './finding-service-provider/finding-service-provider.component'
import { OnOurWayComponent } from './on-our-way/on-our-way.component'
import { ServiceSummaryImprovementComponent } from './service-summary-improvement/service-summary-improvement.component'
import { DashboardComponent } from './dashboard.component'
import { ServiceTrackerHeaderComponent } from './service-tracker-header/service-tracker-header.component'
import { TrackingInfoComponent } from './tracking-info/tracking-info.component'
import { ArrivalTimeLabelComponent } from './arrival-time-label/arrival-time-label.component'
import { CallStatusTimelineComponent } from './call-status-timeline/call-status-timeline.component'
import { CallStatusTrackerComponent } from './call-status-tracker/call-status-tracker.component'
import { MapServiceTrackingComponent } from '../location/map-service-tracking/map-service-tracking.component';
import { ServiceTrackingComponent } from '../service-tracking/service-tracking.component';
import { ServiceTrackingStarterComponent } from '../service-tracking/service-tracking-starter.component';
import { CallStatusHistoryComponent } from '../service-tracking/call-status-history/call-status-history.component';

export function dashboardComponents() {
  return [
    ArrivalTimeSummaryComponent,
    ProgressMapComponent,
    MapServiceTrackingComponent,
    ServiceSummaryComponent,
    StatusUpdateComponent,
    BatteryQuotesBannerComponent,
    ExternalTrackerComponent,
    ProgressBarComponent,
    InformativeContentComponent,
    CallStatusItemComponent,
    CallStatusTimelineComponent,
    CallStatusTrackerComponent,
    EtaLabelComponent,
    ArrivalTimeLabelComponent,
    FindingServiceProviderComponent,
    ServiceSummaryImprovementComponent,
    DashboardComponent,
    ServiceTrackingStarterComponent,
    ServiceTrackingComponent,
    CallStatusHistoryComponent,
    ServiceTrackerHeaderComponent,
    OnOurWayComponent,
    ServiceSummaryImprovementComponent,
    TrackingInfoComponent
  ]
}
