<ion-card [ngClass]="{'no-box-shadow': !borderShadow}">
  <ion-card-content>
    <ion-row>
      <ion-col>
        <aaa-text color="text" display="block" size="subheadline" weight="bold" class="destination-name">
          {{ title }}
        </aaa-text>
        <aaa-text weight="semibold">
          <p>
            <aaa-text size="footnote" display="inline" color="subtle">
              <app-distance-display [distance]="distance" [short]="true"></app-distance-display>
            </aaa-text>
            <aaa-text *ngIf="status" size="caption" display="inline" color="valid">{{ status }}</aaa-text>
            <aaa-text *ngIf="nextAction" size="caption2" display="inline" color="faint">{{ nextAction }}</aaa-text>
          </p>
        </aaa-text>
      </ion-col>

      <ion-col size="auto" *ngIf="closeable">
        <ion-button
          color="secondary"
          size="small"
          shape="round"
          class="ion-no-margin close-icon"
          (click)="close.emit()"
          appCtaClickEvent="Destination Card Closed"
        >
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ng-content select="[content]"></ng-content>

    <ng-content select="[footer]"></ng-content>
  </ion-card-content>
</ion-card>
