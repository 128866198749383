import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { Router } from '@angular/router'
import { CANCEL_REQUEST, cancelEditingRequest } from '../../shared/actions/shared.actions'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { IndexedCollection, PayloadedAction } from '../../shared/types'
import { AAAStore } from '../../store/root-reducer'
import { selectBreakdownLocationInterstateSelected, selectIsHighway } from '../location/location.selectors'
import {
  selectServicingClubAccidentPriorityCode,
  selectServicingClubHighwayPriorityCode,
  selectServicingClubPacesetterAccident
} from '../servicing-club/servicing-club.selectors'
import { TaggingService } from '../tagging/tagging.service'
import { openMessageDialog, openPromptDialog, removeVehicleStep } from '../ui/ui.actions'
import { MessageDialogTypes, PromptDialogTypes, StepTypes } from '../ui/ui.types'
import { callStatusRequest, SET_ACTIVE_CALL_STATUS, setActiveCallStatus } from './calls-statuses/call-status.actions'
import {
  selectActiveCallStatus,
  selectActiveCallStatusId,
  selectCallsStatusesData,
  selectCanCancelActiveCall,
  selectFollowingCallsStatusId,
  selectIsActiveBatteryCall
} from './calls-statuses/call-status.selectors'
import {
  ADD_CALL,
  ADD_PARTIAL_CALL,
  addCallRequest,
  addCallSuccess,
  addPartialCallSuccess,
  CALL_CANCEL,
  callCancelRequest,
  callCancelSuccess,
  notifyCallCancelFailure,
  notifyPartialCallFailure,
  resetActiveCallsCalled,
  resetCallStatusError,
  setCallTowing,
  setServiceDeliveryCallStatusFailure,
  setServiceDeliveryCallStatusSuccess
} from './calls.actions'
import { selectCallData } from './calls.selectors'
import { CallsService } from './calls.service'
import { AAACallData, AAACallStatus, CALL_STATUS_CODES, CallResponse, ServiceDeliveryCallStatus } from './calls.types'
import { generateCallId } from './calls.utils'

import { DateTime } from 'luxon'
import {
  selectAgentSettings,
  selectAuthClub,
  selectIsAgent,
  selectIsRapUser,
  selectIsVehicleChangeAllowed
} from '../auth/auth.selectors'
import { PACE_SETTER_SITUATION_CODES, PRIORITY_CODES } from '../issue/issue.types'
import { RouteTypes } from '../main-router.module'
import { selectHasBatteryQuotes } from '../quotes/quotes.selectors'
import { selectNumberOfPassengers } from '../submit/submit.selectors'
import events from '../tagging/events'
import { CallStatusService } from './calls-statuses/call-status.service'

import { selectChannel } from '../ui/ui.selectors';
import { selectAdditionalRequirementSelected, selectServicingClubLockoutPriorityCode } from '../issue/issue.selectors'
import { isAgentAppId } from '../ui/ui.utils';
import { assignEligibilityVehicle } from '../member/member.actions';
import { AdobeEventTypes } from '../tagging/tagging.types'
import { AdobeEventService } from '../tagging/adobe/event-adobe.service'
import { DRR_BASE_HREF } from '../../shared/shared.config'

@Injectable()
export class CallEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _callService: CallsService,
    private _callStatusService: CallStatusService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}

  addCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addCallRequest>>(ADD_CALL.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectAgentSettings)),
        this.store$.pipe(select(selectCallData)),
        this.store$.pipe(select(selectIsHighway)),
        this.store$.pipe(select(selectServicingClubHighwayPriorityCode)),
        this.store$.pipe(select(selectServicingClubAccidentPriorityCode)),
        this.store$.pipe(select(selectIsAgent)),
        this.store$.pipe(select(selectNumberOfPassengers)),
        this.store$.pipe(select(selectBreakdownLocationInterstateSelected)),
        this.store$.pipe(select(selectAdditionalRequirementSelected)),
        this.store$.pipe(select(selectChannel)),
        this.store$.pipe(select(selectServicingClubLockoutPriorityCode)),
        this.store$.pipe(select(selectServicingClubPacesetterAccident)),
      ),
      map(([_, agentSettings, callData, isHighway, highwayPriorityCode, accidentPriorityCode, isAgent, numberOfPassengers, onInterState, isPrioritySituation, channel, lockoutPriorityCode, servicingClubPacesetterAccident]) => {
        if(channel) {
          callData.channel = channel
        }

        // overwrite if is priority situation
        if(isPrioritySituation || lockoutPriorityCode) {
          callData.priorityCode = lockoutPriorityCode || (isPrioritySituation ? PRIORITY_CODES.P1 : callData.priorityCode)
        }

        // keep same logic to allow clubs to override the pacesetter codes
        const situationCode = callData.situationCodes
        if (situationCode && (
          situationCode.pacesetterCode === PACE_SETTER_SITUATION_CODES.T906 ||
          situationCode.pacesetterCode === servicingClubPacesetterAccident
        )) {
          callData.priorityCode = accidentPriorityCode
        } else if ((isHighway && highwayPriorityCode) || (onInterState && highwayPriorityCode)) {
          callData.priorityCode = highwayPriorityCode
        }

        if (numberOfPassengers) {
          callData.numberOfPassengers = numberOfPassengers
        }
        const { agentName, agentUsername, cashCall, vehicleType, agentFirstName, agentLastName } =
          agentSettings || {}
        if (isAgentAppId()) {
          return {
            ...callData,
            agentFirstName: agentFirstName || '',
            agentLastName: agentLastName || '',
          }
        }
        return isAgent
          ? {
            ...callData,
            callTaker: agentName || agentUsername,
            priorityCode: agentSettings.priorityCode,
            cashRequired: cashCall,
            vehicle: { ...callData.vehicle, vehicleType },
          }
          : callData
      }),
      switchMap((callData: AAACallData) =>
        from(this._callService.addCall(callData)).pipe(
          withLatestFrom(this.store$.pipe(select(selectAuthClub))),
          filter((call) => Boolean(call)),
          concatMap(([call, authClub]: [CallResponse, string]) => {
            this.router.navigate([this.drrBaseHref, RouteTypes.DASHBOARD])
            // TODO improve function call signature

            this.adobeEventService.sendEvent({
              eventName: AdobeEventTypes.SYSTEM,
              eventValue: events.submit.SUMMARY_CALL_REQUEST_SUCCESS
            },
            null,
            {
              ers_call_id: call.callId,
              servicing_club: call.servicingClub,
              club: authClub
            }
            )

            this.tagging.setAutomatedEvent(
              events.submit.SUBMIT_SUCCESS,
              events.submit.SUBMIT_PAGE_TYPE, {
                callIdentifier: generateCallId(call.callId, call.callDate),
                callStatus: CALL_STATUS_CODES.NEW
              },
              {
                callId: call.callId,
                servicingClub: call.servicingClub,
              }
            )
            this._callStatusService.startCallStatusesUpdater({ retry: true })

            return [
              addCallSuccess({
                payload: {
                  response: {
                    ...call,
                    callStatus: CALL_STATUS_CODES.NEW,
                  },
                  data: callData,
                },
              }),
              setActiveCallStatus({
                payload: { id: generateCallId(call.callId, call.callDate) },
              }),
            ]
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              [
                resetActiveCallsCalled(),
                callStatusRequest({
                  payload: { retry: false, addCallFailure: true },
                })
              ]
            ))
        )
      ),
      catchError((error) =>
        this.errorReportingService.notifyErrorObservable(
          error,
          [
            resetActiveCallsCalled(),
            callStatusRequest({
              payload: { retry: false, addCallFailure: true },
            })
          ]
        )
      )
    )
  )

  // start ARR:POC - The effect for calling the service delivery service
  setServiceDeliveryStatus$ = createEffect(
    (): Observable<
      | ReturnType<typeof setServiceDeliveryCallStatusSuccess>
      | ReturnType<typeof setServiceDeliveryCallStatusFailure>
    > =>
      this.actions$.pipe(
        ofType<ReturnType<typeof addCallSuccess>>(ADD_CALL.SUCCESS),
        withLatestFrom(this.store$.pipe(select(selectIsAgent))),
        filter(([_, isAgent]) => isAgent),
        switchMap((params) =>
          of(params).pipe(
            mergeMap(([callSubmission]) => {
              const id = callSubmission.payload.response.callId
              const date = DateTime.fromISO(
                callSubmission.payload.response.callDate
              ).toISODate()

              return from(
                this._callService.getServiceDeliveryStatus(id, date)
              ).pipe(
                map((data: ServiceDeliveryCallStatus) =>
                  setServiceDeliveryCallStatusSuccess({ payload: data })
                ),
                catchError((error) =>
                  this.errorReportingService.notifyErrorObservable(
                    error,
                    setServiceDeliveryCallStatusFailure
                  )
                )
              )
            }),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                setServiceDeliveryCallStatusFailure
              )
            )
          )
        )
      )
  )
  // end ARR:POC

  addPartialCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_PARTIAL_CALL.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectCallData)),
        this.store$.pipe(select(selectIsHighway)),
        this.store$.pipe(select(selectServicingClubHighwayPriorityCode)),
        this.store$.pipe(select(selectNumberOfPassengers)),
        this.store$.pipe(select(selectBreakdownLocationInterstateSelected)),
        this.store$.pipe(select(selectAdditionalRequirementSelected)),
        this.store$.pipe(select(selectServicingClubLockoutPriorityCode))
      ),
      map(([_, callData, isHighway, priorityCode, numberOfPassengers, onInterState, isPrioritySituation, lockoutPriorityCode]) => {

        // overwrite if is priority situation
        if(isPrioritySituation || lockoutPriorityCode) {
          callData.priorityCode = lockoutPriorityCode || (isPrioritySituation ? PRIORITY_CODES.P1 : callData.priorityCode)
        }

        // keep same logic to allow clubs to override the pacesetter codes
        if ((isHighway && priorityCode) || (onInterState && priorityCode)) {
          callData.priorityCode = priorityCode
        }
        if (numberOfPassengers >= 0) {
          callData.numberOfPassengers = numberOfPassengers
        }

        return callData
      }),
      switchMap((callData: AAACallData) =>
        from(this._callService.addPartialCall(callData)).pipe(
          map(() => addPartialCallSuccess()),
          catchError((error) => this.errorReportingService.notifyErrorObservable(
            error,
            notifyPartialCallFailure
          ))
        )
      )
    )
  )

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CANCEL_REQUEST),
      switchMap((action) =>
        of(action).pipe(
          tap(() => {
            // reset call status error
            this.store$.dispatch(resetCallStatusError())
          }),
          withLatestFrom(
            this.store$.pipe(select(selectActiveCallStatus)),
            this.store$.pipe(select(selectCanCancelActiveCall))
          ),
          switchMap(([_, activeCallStatus, canCancel]) => {
            if (canCancel) {
              const { updateToken, callDate, callId, servicingClub } =
                activeCallStatus

              return [
                cancelEditingRequest(),
                callCancelRequest({
                  payload: {
                    updateToken,
                    callDate,
                    callId,
                    servicingClub,
                  },
                }),
              ]
            } else {
              return [cancelEditingRequest()]
            }
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifyCallCancelFailure
            )
          )
        )
      )
    )
  )

  // Switch calls when the user wants to cancel the active one
  changeCallOnCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.REQUEST),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectFollowingCallsStatusId))
          ),
          filter(([_, nextAvailableId]: [Action, string]) => !!nextAvailableId),
          tap(() => {
            // reset call status error
            this.store$.dispatch(resetCallStatusError())
          }),
          map(([_, nextAvailableId]: [Action, string]) =>
            setActiveCallStatus({
              payload: {
                id: nextAvailableId,
              },
            })
          )
        )
      )
    )
  )

  cancelCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.REQUEST),
      switchMap((action: PayloadedAction) =>
        from(this._callService.cancelCall(action.payload)).pipe(
          switchMap(() => {
            this.tagging.setAutomatedEvent(
              events.dashboard.CALL_CANCEL_SUCCESS,
              events.dashboard.CALL_STATUS_PAGE_TYPE
            )
            this.adobeEventService.sendEvent({
              eventName: AdobeEventTypes.SYSTEM,
              eventValue: events.dashboard.CANCEL_CALL_SUCCESS
            })
            this._callStatusService.stopCallStatusesUpdater()

            return [
              callCancelSuccess({
                payload: {
                  callId: action.payload.callId,
                  callDate: action.payload.callDate,
                },
              }),
              resetCallStatusError(),
            ]
          }),
          catchError((error) =>
            this._automatedEventAndNotifyFailure(
              error,
              events.dashboard.CALL_CANCEL_FAILURE,
              notifyCallCancelFailure,
              { callId: selectActiveCallStatusId }
            )
          )
        )
      )
    )
  )

  cancelCallSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CALL_CANCEL.SUCCESS),
      withLatestFrom(
        this.store$.pipe(select(selectIsVehicleChangeAllowed)),
        this.store$.pipe(select(selectIsRapUser)),
      ),
      switchMap(([_, isVehicleChangeAllowed, isRapUser]) => {
        this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
          queryParams: { step: StepTypes.BREAKDOWN_LOCATION, cancel: 'true' },
        })
        const actions = []
        if (isRapUser) {
          actions.push(openMessageDialog({
            payload: {
              type: MessageDialogTypes.CALL_CANCELLED,
            }
          }))
        } else {
          actions.push(openPromptDialog({
            payload: {
              type: PromptDialogTypes.CANCEL_FEEDBACK_PROMPT,
              params: { hasAvailableCall: true }
            },
          }))
        }

        if (!isVehicleChangeAllowed) {
          actions.push(removeVehicleStep())
          actions.push(assignEligibilityVehicle())
        }
        return actions
      })
    )
  )

  updateCallTowDestination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_ACTIVE_CALL_STATUS),
      withLatestFrom(this.store$.pipe(select(selectCallsStatusesData))),
      filter(
        ([action, data]: [PayloadedAction, IndexedCollection<AAACallStatus>]) =>
          !!data[action.payload.id]
      ),
      map(
        ([action, data]: [
          PayloadedAction,
          IndexedCollection<AAACallStatus>
        ]) => {
          const activeCallStatus: AAACallStatus = data[action.payload.id]

          return setCallTowing({
            payload: activeCallStatus.towDestination
              ? {
                ...activeCallStatus.towDestination,
                name: activeCallStatus.towDestination.location,
                latitude: activeCallStatus.towDestination.latitude,
                longitude: activeCallStatus.towDestination.longitude,
              }
              : null,
          })
        }
      )
    )
  )

  // Show welcome once for each call submitted.
  handleShowWelcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_CALL.SUCCESS),
      switchMap(() =>
        this.store$.select(selectIsActiveBatteryCall).pipe(
          filter(isActiveBatteryCall => isActiveBatteryCall !== null),
          withLatestFrom(
            this.store$.select(selectHasBatteryQuotes),
            this.store$.select(selectIsRapUser)
          )
        )
      ),
      map(([isActiveBatteryCall, hasBatteryQuotes, isRapUser]) => {
        const textDialog = isRapUser
          ? $localize`Please be with the vehicle when assistance arrives.`
          : $localize`The AAA member is required to be with the vehicle when assistance arrives.<br> Please be prepared to show Photo ID.`;

        if (isActiveBatteryCall && !hasBatteryQuotes) {
          return openPromptDialog({
            payload: {
              type: PromptDialogTypes.SHOW_BATTERY_QUOTES,
            },
          })
        } else {
          return openMessageDialog({
            payload: {
              type: MessageDialogTypes.CUSTOM,
              title: $localize`On Our Way!`,
              content: textDialog,
              scrollToTopOnClose: true
            },
          })
        }
      })
    )
  )

  _automatedEventAndNotifyFailure = (
    error,
    eventAction,
    notifyAction,
    notifyPayload?
  ) => {
    const payload = !notifyPayload ? {} : notifyPayload
    const observableAction = this.errorReportingService.notifyErrorObservable(
      error,
      notifyAction,
      payload
    )
    try {
      this.tagging.setAutomatedEvent(eventAction, events.submit.SUBMIT_PAGE_TYPE)
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.SYSTEM,
        eventValue: events.dashboard.CANCEL_CALL_FAILED
      })
    } catch (e) {
      console.error('Failure tagging automated event: ', e)
    }
    return observableAction
  }
}
